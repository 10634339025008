/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      shortDescription
      description
      categories
      tags
      price
      salePrice
      saleFrom
      saleTo
      manageStock
      stock
      slug
      type
      state
      generalRules
      cancelationRules
      strikesRules
      trainingType
      generateSubscription
      subscriptionFrequency
      subscriptionSessions
      isTaxable
      dimensions {
        length
        width
        height
      }
      weight
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      resources {
        id
        name
        type
        src {
          bucket
          key
          region
          identityId
        }
        description
        instructions
        onlyOn
        availableOn
      }
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productByName = /* GraphQL */ `
  query ProductByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productBySlug = /* GraphQL */ `
  query ProductBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productByType = /* GraphQL */ `
  query ProductByType(
    $type: ProductType
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productByTrainingType = /* GraphQL */ `
  query ProductByTrainingType(
    $trainingType: TrainingType
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByTrainingType(
      trainingType: $trainingType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productByStateTrainingType = /* GraphQL */ `
  query ProductByStateTrainingType(
    $state: State
    $trainingType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByStateTrainingType(
      state: $state
      trainingType: $trainingType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerSubscription = /* GraphQL */ `
  query GetCustomerSubscription($id: ID!) {
    getCustomerSubscription(id: $id) {
      id
      dateCreated
      autoRenew
      nextPaymentDate
      productID
      customerID
      orderID
      lastRenewal
      remainingSessions
      createdAt
      updatedAt
      product {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCustomerSubscriptions = /* GraphQL */ `
  query ListCustomerSubscriptions(
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateCreated
        autoRenew
        nextPaymentDate
        productID
        customerID
        orderID
        lastRenewal
        remainingSessions
        createdAt
        updatedAt
        product {
          id
          name
          shortDescription
          description
          categories
          tags
          price
          salePrice
          saleFrom
          saleTo
          manageStock
          stock
          slug
          type
          state
          generalRules
          cancelationRules
          strikesRules
          trainingType
          generateSubscription
          subscriptionFrequency
          subscriptionSessions
          isTaxable
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          resources {
            id
            name
            type
            src {
              bucket
              key
              region
              identityId
            }
            description
            instructions
            onlyOn
            availableOn
          }
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const subscriptionByCustomer = /* GraphQL */ `
  query SubscriptionByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateCreated
        autoRenew
        nextPaymentDate
        productID
        customerID
        orderID
        lastRenewal
        remainingSessions
        createdAt
        updatedAt
        product {
          id
          name
          shortDescription
          description
          categories
          tags
          price
          salePrice
          saleFrom
          saleTo
          manageStock
          stock
          slug
          type
          state
          generalRules
          cancelationRules
          strikesRules
          trainingType
          generateSubscription
          subscriptionFrequency
          subscriptionSessions
          isTaxable
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          resources {
            id
            name
            type
            src {
              bucket
              key
              region
              identityId
            }
            description
            instructions
            onlyOn
            availableOn
          }
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const subscriptionByOrder = /* GraphQL */ `
  query SubscriptionByOrder(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByOrder(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateCreated
        autoRenew
        nextPaymentDate
        productID
        customerID
        orderID
        lastRenewal
        remainingSessions
        createdAt
        updatedAt
        product {
          id
          name
          shortDescription
          description
          categories
          tags
          price
          salePrice
          saleFrom
          saleTo
          manageStock
          stock
          slug
          type
          state
          generalRules
          cancelationRules
          strikesRules
          trainingType
          generateSubscription
          subscriptionFrequency
          subscriptionSessions
          isTaxable
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          resources {
            id
            name
            type
            src {
              bucket
              key
              region
              identityId
            }
            description
            instructions
            onlyOn
            availableOn
          }
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCustomerProgress = /* GraphQL */ `
  query GetCustomerProgress($id: ID!) {
    getCustomerProgress(id: $id) {
      id
      customerID
      dateCreated
      measurements {
        weight
        chest
        waist
        hips
        biceps
        thighs
      }
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerProgresss = /* GraphQL */ `
  query ListCustomerProgresss(
    $filter: ModelCustomerProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerProgresss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        dateCreated
        measurements {
          weight
          chest
          waist
          hips
          biceps
          thighs
        }
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const progressByCustomer = /* GraphQL */ `
  query ProgressByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    progressByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        dateCreated
        measurements {
          weight
          chest
          waist
          hips
          biceps
          thighs
        }
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      email
      phone
      profilePic {
        bucket
        key
        region
        identityId
      }
      gender
      height
      weight
      weightGoal
      interests
      billingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      shippingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      stripeInfo {
        customerId
        payment {
          id
          brand
          last4
          expMonth
          expYear
        }
      }
      strikes
      isAdmin
      isPetuPunchAdmin
      pin
      vacuID {
        bucket
        key
        region
        identityId
      }
      dateCreated
      lastUpdate
      searchString
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          dateCreated
          autoRenew
          nextPaymentDate
          productID
          customerID
          orderID
          lastRenewal
          remainingSessions
          createdAt
          updatedAt
          product {
            id
            name
            shortDescription
            description
            categories
            tags
            price
            salePrice
            saleFrom
            saleTo
            manageStock
            stock
            slug
            type
            state
            generalRules
            cancelationRules
            strikesRules
            trainingType
            generateSubscription
            subscriptionFrequency
            subscriptionSessions
            isTaxable
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
            resources {
              id
              name
              type
              description
              instructions
              onlyOn
              availableOn
            }
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      progress {
        items {
          id
          customerID
          dateCreated
          measurements {
            weight
            chest
            waist
            hips
            biceps
            thighs
          }
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      resources {
        items {
          id
          dateCreated
          resourceID
          productID
          customerID
          resource {
            bucket
            key
            region
            identityId
          }
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      checkIns {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      cart {
        id
        customerID
        products {
          productId
          qty
        }
        coupons
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          orderNumber
          orderDate
          customerID
          products {
            productId
            qty
            price
            tax
            total
            isTaxable
            name
            type
            trainingType
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
          }
          subtotal
          discounts
          shipping
          tax
          total
          coupons
          notes {
            customerID
            message
            dateCreated
          }
          paymentMethod
          paymentConfirmation
          status
          shippingCarrier
          trackingNumber
          fromSubscriptionID
          lastUpdate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customerByEmail = /* GraphQL */ `
  query CustomerByEmail(
    $email: AWSEmail
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customerByFirstName = /* GraphQL */ `
  query CustomerByFirstName(
    $firstName: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByFirstName(
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customerByLastName = /* GraphQL */ `
  query CustomerByLastName(
    $lastName: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByLastName(
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customerByPin = /* GraphQL */ `
  query CustomerByPin(
    $pin: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByPin(
      pin: $pin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getCustomerResource = /* GraphQL */ `
  query GetCustomerResource($id: ID!) {
    getCustomerResource(id: $id) {
      id
      dateCreated
      resourceID
      productID
      customerID
      resource {
        bucket
        key
        region
        identityId
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listCustomerResources = /* GraphQL */ `
  query ListCustomerResources(
    $filter: ModelCustomerResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateCreated
        resourceID
        productID
        customerID
        resource {
          bucket
          key
          region
          identityId
        }
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const resourceByCustomer = /* GraphQL */ `
  query ResourceByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateCreated
        resourceID
        productID
        customerID
        resource {
          bucket
          key
          region
          identityId
        }
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      name
      userId
      dateCreated
      type
      createdAt
      updatedAt
      checkIn {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        userId
        dateCreated
        type
        createdAt
        updatedAt
        checkIn {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sessionByDate = /* GraphQL */ `
  query SessionByDate(
    $type: SessionType
    $dateCreated: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionByDate(
      type: $type
      dateCreated: $dateCreated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        userId
        dateCreated
        type
        createdAt
        updatedAt
        checkIn {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($id: ID!) {
    getCheckIn(id: $id) {
      id
      checkinDate
      customerID
      sessionID
      subscriptionID
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        checkinDate
        customerID
        sessionID
        subscriptionID
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const checkinByCustomer = /* GraphQL */ `
  query CheckinByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkinDate
        customerID
        sessionID
        subscriptionID
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const checkinBySession = /* GraphQL */ `
  query CheckinBySession(
    $sessionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinBySession(
      sessionID: $sessionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkinDate
        customerID
        sessionID
        subscriptionID
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      customerID
      products {
        productId
        qty
      }
      coupons
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        products {
          productId
          qty
        }
        coupons
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerCart = /* GraphQL */ `
  query CustomerCart(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerCart(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        products {
          productId
          qty
        }
        coupons
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      code
      description
      amount
      type
      rules {
        expirationDate
        minItems
        canCombine
        minCartAmount
        maxUse
      }
      state
      timesUsed
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        description
        amount
        type
        rules {
          expirationDate
          minItems
          canCombine
          minCartAmount
          maxUse
        }
        state
        timesUsed
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      orderNumber
      orderDate
      customerID
      products {
        productId
        qty
        price
        tax
        total
        isTaxable
        name
        type
        trainingType
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
      }
      subtotal
      discounts
      shipping
      tax
      total
      coupons
      notes {
        customerID
        message
        dateCreated
      }
      paymentMethod
      paymentConfirmation
      status
      shippingCarrier
      trackingNumber
      fromSubscriptionID
      lastUpdate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderNumber
        orderDate
        customerID
        products {
          productId
          qty
          price
          tax
          total
          isTaxable
          name
          type
          trainingType
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
        }
        subtotal
        discounts
        shipping
        tax
        total
        coupons
        notes {
          customerID
          message
          dateCreated
        }
        paymentMethod
        paymentConfirmation
        status
        shippingCarrier
        trackingNumber
        fromSubscriptionID
        lastUpdate
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const customerOrders = /* GraphQL */ `
  query CustomerOrders(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerOrders(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderNumber
        orderDate
        customerID
        products {
          productId
          qty
          price
          tax
          total
          isTaxable
          name
          type
          trainingType
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
        }
        subtotal
        discounts
        shipping
        tax
        total
        coupons
        notes {
          customerID
          message
          dateCreated
        }
        paymentMethod
        paymentConfirmation
        status
        shippingCarrier
        trackingNumber
        fromSubscriptionID
        lastUpdate
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ordersByDate = /* GraphQL */ `
  query OrdersByDate(
    $id: ID
    $orderDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDate(
      id: $id
      orderDate: $orderDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderNumber
        orderDate
        customerID
        products {
          productId
          qty
          price
          tax
          total
          isTaxable
          name
          type
          trainingType
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
        }
        subtotal
        discounts
        shipping
        tax
        total
        coupons
        notes {
          customerID
          message
          dateCreated
        }
        paymentMethod
        paymentConfirmation
        status
        shippingCarrier
        trackingNumber
        fromSubscriptionID
        lastUpdate
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ordersByNumber = /* GraphQL */ `
  query OrdersByNumber(
    $orderNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByNumber(
      orderNumber: $orderNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderNumber
        orderDate
        customerID
        products {
          productId
          qty
          price
          tax
          total
          isTaxable
          name
          type
          trainingType
          dimensions {
            length
            width
            height
          }
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
        }
        subtotal
        discounts
        shipping
        tax
        total
        coupons
        notes {
          customerID
          message
          dateCreated
        }
        paymentMethod
        paymentConfirmation
        status
        shippingCarrier
        trackingNumber
        fromSubscriptionID
        lastUpdate
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getContactForm = /* GraphQL */ `
  query GetContactForm($id: ID!) {
    getContactForm(id: $id) {
      id
      name
      message
      email
      createdAt
      updatedAt
    }
  }
`;
export const listContactForms = /* GraphQL */ `
  query ListContactForms(
    $filter: ModelContactFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        message
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getErrorLog = /* GraphQL */ `
  query GetErrorLog($id: ID!) {
    getErrorLog(id: $id) {
      id
      method
      error
      stackTrace
      data
      createdAt
      updatedAt
    }
  }
`;
export const listErrorLogs = /* GraphQL */ `
  query ListErrorLogs(
    $filter: ModelErrorLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        method
        error
        stackTrace
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimestamp = /* GraphQL */ `
  query GetTimestamp($id: ID!) {
    getTimestamp(id: $id) {
      id
      customerID
      date
      time
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listTimestamps = /* GraphQL */ `
  query ListTimestamps(
    $filter: ModelTimestampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimestamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        date
        time
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const timestampByCustomer = /* GraphQL */ `
  query TimestampByCustomer(
    $customerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTimestampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timestampByCustomer(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        date
        time
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const timestampByDate = /* GraphQL */ `
  query TimestampByDate(
    $date: AWSDate
    $sortDirection: ModelSortDirection
    $filter: ModelTimestampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timestampByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        date
        time
        createdAt
        updatedAt
        customer {
          id
          firstName
          lastName
          email
          phone
          profilePic {
            bucket
            key
            region
            identityId
          }
          gender
          height
          weight
          weightGoal
          interests
          billingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          shippingAddress {
            line1
            line2
            city
            state
            zipCode
          }
          stripeInfo {
            customerId
            payment {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          strikes
          isAdmin
          isPetuPunchAdmin
          pin
          vacuID {
            bucket
            key
            region
            identityId
          }
          dateCreated
          lastUpdate
          searchString
          createdAt
          updatedAt
          subscriptions {
            items {
              id
              dateCreated
              autoRenew
              nextPaymentDate
              productID
              customerID
              orderID
              lastRenewal
              remainingSessions
              createdAt
              updatedAt
            }
            nextToken
          }
          progress {
            items {
              id
              customerID
              dateCreated
              createdAt
              updatedAt
            }
            nextToken
          }
          resources {
            items {
              id
              dateCreated
              resourceID
              productID
              customerID
              createdAt
              updatedAt
            }
            nextToken
          }
          checkIns {
            items {
              id
              checkinDate
              customerID
              sessionID
              subscriptionID
              createdAt
              updatedAt
            }
            nextToken
          }
          cart {
            id
            customerID
            products {
              productId
              qty
            }
            coupons
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
          orders {
            items {
              id
              orderNumber
              orderDate
              customerID
              subtotal
              discounts
              shipping
              tax
              total
              coupons
              paymentMethod
              paymentConfirmation
              status
              shippingCarrier
              trackingNumber
              fromSubscriptionID
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
