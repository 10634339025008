// 
export const getCustomerByEmail = /* GraphQL */ `
    query customerByEmail($email: AWSEmail!) {
        customerByEmail(email: $email) {
            items {
                id
                firstName
                lastName
                email
                phone
                profilePic {
                    bucket
                    key
                    region
                    identityId
                }
                isPetuPunchAdmin
                pin
                vacuID {
                  bucket
                  key
                  region
                  identityId
                }
                gender
                height
                weight
                weightGoal
                interests
                billingAddress {
                    line1
                    line2
                    city
                    state
                    zipCode
                }
                shippingAddress {
                    line1
                    line2
                    city
                    state
                    zipCode
                }
                stripeInfo {
                    customerId
                    payment {
                    id
                    brand
                    last4
                    expMonth
                    expYear
                    }
                }
                strikes
                isAdmin
                dateCreated
                lastUpdate
                createdAt
                updatedAt
                subscriptions {
                    items {
                    id
                    dateCreated
                    autoRenew
                    nextPaymentDate
                    productID
                    customerID
                    orderID
                    remainingSessions
                    createdAt
                    updatedAt
                    product {
                        id
                        name
                        shortDescription
                        description
                        categories
                        tags
                        price
                        salePrice
                        saleFrom
                        saleTo
                        manageStock
                        stock
                        slug
                        type
                        state
                        generalRules
                        cancelationRules
                        strikesRules
                        trainingType
                        generateSubscription
                        subscriptionFrequency
                        subscriptionSessions
                        isTaxable
                        dimensions {
                        length
                        width
                        height
                        }
                        weight
                        images {
                        id
                        primary
                        }
                        resources {
                        id
                        name
                        type
                        description
                        instructions
                        onlyOn
                        availableOn
                        }
                        dateCreated
                        lastUpdate
                        createdAt
                        updatedAt
                    }
                    }
                    nextToken
                }
                resources {
                    items {
                    id
                    dateCreated
                    resourceID
                    productID
                    customerID
                    resource {
                        bucket
                        key
                        region
                        identityId
                    }
                    createdAt
                    updatedAt
                    }
                    nextToken
                }
                checkIns {
                    items {
                    id
                    checkinDate
                    customerID
                    sessionID
                    subscriptionID
                    createdAt
                    updatedAt
                    customer {
                        id
                        firstName
                        lastName
                        email
                        phone
                        profilePic {
                        bucket
                        key
                        region
                        identityId
                        }
                        gender
                        height
                        weight
                        weightGoal
                        interests
                        billingAddress {
                        line1
                        line2
                        city
                        state
                        zipCode
                        }
                        shippingAddress {
                        line1
                        line2
                        city
                        state
                        zipCode
                        }
                        stripeInfo {
                        customerId
                        }
                        strikes
                        isAdmin
                        dateCreated
                        lastUpdate
                        createdAt
                        updatedAt
                        subscriptions {
                        nextToken
                        }
                        resources {
                        nextToken
                        }
                        checkIns {
                        nextToken
                        }
                        cart {
                        id
                        customerID
                        coupons
                        dateCreated
                        lastUpdate
                        createdAt
                        updatedAt
                        }
                        orders {
                        nextToken
                        }
                    }
                    }
                    nextToken
                }
                cart {
                    id
                    customerID
                    products {
                    productId
                    qty
                    }
                    coupons
                    dateCreated
                    lastUpdate
                    createdAt
                    updatedAt
                }
                orders {
                    items {
                    id
                    orderNumber
                    orderDate
                    customerID
                    products {
                        productId
                        qty
                        price
                        tax
                        total
                        isTaxable
                        name
                        type
                        trainingType
                        dimensions {
                        length
                        width
                        height
                        }
                        weight
                        images {
                        id
                        primary
                        }
                    }
                    subtotal
                    discounts
                    shipping
                    tax
                    total
                    coupons
                    notes {
                        customerID
                        message
                        dateCreated
                    }
                    paymentMethod
                    paymentConfirmation
                    status
                    shippingCarrier
                    trackingNumber
                    fromSubscriptionID
                    lastUpdate
                    createdAt
                    updatedAt
                    customer {
                        id
                        firstName
                        lastName
                        email
                        phone
                        profilePic {
                        bucket
                        key
                        region
                        identityId
                        }
                        gender
                        height
                        weight
                        weightGoal
                        interests
                        billingAddress {
                        line1
                        line2
                        city
                        state
                        zipCode
                        }
                        shippingAddress {
                        line1
                        line2
                        city
                        state
                        zipCode
                        }
                        stripeInfo {
                        customerId
                        }
                        strikes
                        isAdmin
                        dateCreated
                        lastUpdate
                        createdAt
                        updatedAt
                        subscriptions {
                        nextToken
                        }
                        resources {
                        nextToken
                        }
                        checkIns {
                        nextToken
                        }
                        cart {
                        id
                        customerID
                        coupons
                        dateCreated
                        lastUpdate
                        createdAt
                        updatedAt
                        }
                        orders {
                        nextToken
                        }
                    }
                }
                nextToken
            }
        }
    }
}
`;
export const getSubscriptionByCustomer = /* GraphQL */ `
  query subscriptionByCustomer(
      $customerID: ID!
      $filter: ModelCustomerSubscriptionFilterInput
      $limit: Int
    ) {
      subscriptionByCustomer(
          customerID: $customerID
          filter: $filter
          limit: $limit
        ) {
        items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            remainingSessions
            createdAt
            updatedAt
            product {
                id
                name
                shortDescription
                description
                categories
                tags
                price
                salePrice
                saleFrom
                saleTo
                manageStock
                stock
                slug
                type
                state
                generalRules
                cancelationRules
                strikesRules
                trainingType
                generateSubscription
                subscriptionFrequency
                subscriptionSessions
                isTaxable
                dimensions {
                    length
                    width
                    height
                }
                weight
                images {
                    id
                    src {
                        bucket
                        key
                        region
                        identityId
                    }
                    primary
                    smallThumbnail {
                        bucket
                        key
                        region
                        identityId
                    }
                    mediumThumbnail {
                        bucket
                        key
                        region
                        identityId
                    }
                }
                resources {
                        id
                        name
                        type
                        src {
                        bucket
                        key
                        region
                        identityId
                    }
                    description
                    instructions
                    onlyOn
                    availableOn
                }
                dateCreated
                lastUpdate
                createdAt
                updatedAt
            }
        }
        nextToken
    }
}
`;
export const getCustomerAdmin = /* GraphQL */ `
  query GetCustomerAdmin($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      isAdmin
    }
  }
`;
export const listCustomersFrugal = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
    }
  }
`;
export const getExpiredSubscriptions = /* GraphQL */ `
  query GetExpiredSubscriptions( $today: String ) {
    listCustomerSubscriptions(
      filter: {
            nextPaymentDate: { le: $today },
            remainingSessions: { le: 0 }
      }
    ) {
      items {
        id
        dateCreated
        autoRenew
        nextPaymentDate
        productID
        customerID
        orderID
        remainingSessions
        createdAt
        updatedAt
        product {
          id
          name
          shortDescription
          description
          categories
          tags
          price
          salePrice
          saleFrom
          saleTo
          manageStock
          stock
          slug
          type
          state
          generalRules
          cancelationRules
          strikesRules
          trainingType
          generateSubscription
          subscriptionFrequency
          subscriptionSessions
          isTaxable
          weight
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCustomerByPin = /* GraphQL */ `
    query customerByPin($pin: String!) {
        customerByPin(pin: $pin) {
            items {
                id
                firstName
                lastName
                pin
                profilePic {
                    bucket
                    key
                    region
                    identityId
                }
            }
            nextToken
        }
    }
`;
export const listTimestamps = /* GraphQL */ `
  query ListTimestamps(
    $filter: ModelTimestampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimestamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        date
        time
        createdAt
        updatedAt
        customer {
          firstName
          lastName
          email
          profilePic {
            bucket
            key
            region
            identityId
          }
        }
      }
      nextToken
    }
  }
`;
export const getProductByName = /* GraphQL */ `
    query productByName($name: String!) {
      productByName(name: $name) {
            items {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              dimensions {
                length
                width
                height
              }
              weight
              images {
                id
                src {
                  bucket
                  key
                  region
                  identityId
                }
                primary
                smallThumbnail {
                  bucket
                  key
                  region
                  identityId
                }
                mediumThumbnail {
                  bucket
                  key
                  region
                  identityId
                }
              }
              resources {
                id
                name
                type
                src {
                  bucket
                  key
                  region
                  identityId
                }
                description
                instructions
                onlyOn
                availableOn
              }
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            nextToken
        }
    }
`;
export const getProductFrugal = /* GraphQL */ `
  query GetProductFrugal($id: ID!) {
    getProduct(id: $id) {
      id
      name
      shortDescription
      description
      categories
      tags
      price
      salePrice
      saleFrom
      saleTo
      manageStock
      stock
      slug
      type
      state
      generalRules
      cancelationRules
      strikesRules
      trainingType
      generateSubscription
      subscriptionFrequency
      subscriptionSessions
      isTaxable
      dimensions {
        length
        width
        height
      }
      weight
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      resources {
        id
        name
        type
        src {
          bucket
          key
          region
          identityId
        }
        description
        instructions
        onlyOn
        availableOn
      }
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const getPetuPunchAdmin = /* GraphQL */ `
  query GetPetuPunchAdmin($id: ID!) {
    getCustomer(id: $id) {
      id
      email
      isPetuPunchAdmin
    }
  }
`;
export const listCustomersFrugalV2 = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $filterSubscriptions: ModelCustomerSubscriptionFilterInput
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        searchString
        profilePic {
          bucket
          key
          region
          identityId
        }
        vacuID {
          bucket
          key
          region
          identityId
        }
        subscriptions(filter: $filterSubscriptions) {
            items {
                id
                nextPaymentDate
                product {
                    name
                }
            }
        }
        checkIns {
            items {
                id
                checkinDate
                subscriptionID
            }
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCustomersFrugal = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $filterSubscriptions: ModelCustomerSubscriptionFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
        items {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        vacuID {
          bucket
          key
          region
          identityId
        }
        subscriptions(filter: $filterSubscriptions) {
            items {
                id
                nextPaymentDate
            }
        }
        checkIns {
            items {
                id
                checkinDate
            }
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;