/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      shortDescription
      description
      categories
      tags
      price
      salePrice
      saleFrom
      saleTo
      manageStock
      stock
      slug
      type
      state
      generalRules
      cancelationRules
      strikesRules
      trainingType
      generateSubscription
      subscriptionFrequency
      subscriptionSessions
      isTaxable
      dimensions {
        length
        width
        height
      }
      weight
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      resources {
        id
        name
        type
        src {
          bucket
          key
          region
          identityId
        }
        description
        instructions
        onlyOn
        availableOn
      }
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      shortDescription
      description
      categories
      tags
      price
      salePrice
      saleFrom
      saleTo
      manageStock
      stock
      slug
      type
      state
      generalRules
      cancelationRules
      strikesRules
      trainingType
      generateSubscription
      subscriptionFrequency
      subscriptionSessions
      isTaxable
      dimensions {
        length
        width
        height
      }
      weight
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      resources {
        id
        name
        type
        src {
          bucket
          key
          region
          identityId
        }
        description
        instructions
        onlyOn
        availableOn
      }
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      shortDescription
      description
      categories
      tags
      price
      salePrice
      saleFrom
      saleTo
      manageStock
      stock
      slug
      type
      state
      generalRules
      cancelationRules
      strikesRules
      trainingType
      generateSubscription
      subscriptionFrequency
      subscriptionSessions
      isTaxable
      dimensions {
        length
        width
        height
      }
      weight
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      resources {
        id
        name
        type
        src {
          bucket
          key
          region
          identityId
        }
        description
        instructions
        onlyOn
        availableOn
      }
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerSubscription = /* GraphQL */ `
  mutation CreateCustomerSubscription(
    $input: CreateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    createCustomerSubscription(input: $input, condition: $condition) {
      id
      dateCreated
      autoRenew
      nextPaymentDate
      productID
      customerID
      orderID
      lastRenewal
      remainingSessions
      createdAt
      updatedAt
      product {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCustomerSubscription = /* GraphQL */ `
  mutation DeleteCustomerSubscription(
    $input: DeleteCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    deleteCustomerSubscription(input: $input, condition: $condition) {
      id
      dateCreated
      autoRenew
      nextPaymentDate
      productID
      customerID
      orderID
      lastRenewal
      remainingSessions
      createdAt
      updatedAt
      product {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCustomerProgress = /* GraphQL */ `
  mutation CreateCustomerProgress(
    $input: CreateCustomerProgressInput!
    $condition: ModelCustomerProgressConditionInput
  ) {
    createCustomerProgress(input: $input, condition: $condition) {
      id
      customerID
      dateCreated
      measurements {
        weight
        chest
        waist
        hips
        biceps
        thighs
      }
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerProgress = /* GraphQL */ `
  mutation DeleteCustomerProgress(
    $input: DeleteCustomerProgressInput!
    $condition: ModelCustomerProgressConditionInput
  ) {
    deleteCustomerProgress(input: $input, condition: $condition) {
      id
      customerID
      dateCreated
      measurements {
        weight
        chest
        waist
        hips
        biceps
        thighs
      }
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      profilePic {
        bucket
        key
        region
        identityId
      }
      gender
      height
      weight
      weightGoal
      interests
      billingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      shippingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      stripeInfo {
        customerId
        payment {
          id
          brand
          last4
          expMonth
          expYear
        }
      }
      strikes
      isAdmin
      isPetuPunchAdmin
      pin
      vacuID {
        bucket
        key
        region
        identityId
      }
      dateCreated
      lastUpdate
      searchString
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          dateCreated
          autoRenew
          nextPaymentDate
          productID
          customerID
          orderID
          lastRenewal
          remainingSessions
          createdAt
          updatedAt
          product {
            id
            name
            shortDescription
            description
            categories
            tags
            price
            salePrice
            saleFrom
            saleTo
            manageStock
            stock
            slug
            type
            state
            generalRules
            cancelationRules
            strikesRules
            trainingType
            generateSubscription
            subscriptionFrequency
            subscriptionSessions
            isTaxable
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
            resources {
              id
              name
              type
              description
              instructions
              onlyOn
              availableOn
            }
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      progress {
        items {
          id
          customerID
          dateCreated
          measurements {
            weight
            chest
            waist
            hips
            biceps
            thighs
          }
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      resources {
        items {
          id
          dateCreated
          resourceID
          productID
          customerID
          resource {
            bucket
            key
            region
            identityId
          }
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      checkIns {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      cart {
        id
        customerID
        products {
          productId
          qty
        }
        coupons
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          orderNumber
          orderDate
          customerID
          products {
            productId
            qty
            price
            tax
            total
            isTaxable
            name
            type
            trainingType
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
          }
          subtotal
          discounts
          shipping
          tax
          total
          coupons
          notes {
            customerID
            message
            dateCreated
          }
          paymentMethod
          paymentConfirmation
          status
          shippingCarrier
          trackingNumber
          fromSubscriptionID
          lastUpdate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      profilePic {
        bucket
        key
        region
        identityId
      }
      gender
      height
      weight
      weightGoal
      interests
      billingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      shippingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      stripeInfo {
        customerId
        payment {
          id
          brand
          last4
          expMonth
          expYear
        }
      }
      strikes
      isAdmin
      isPetuPunchAdmin
      pin
      vacuID {
        bucket
        key
        region
        identityId
      }
      dateCreated
      lastUpdate
      searchString
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          dateCreated
          autoRenew
          nextPaymentDate
          productID
          customerID
          orderID
          lastRenewal
          remainingSessions
          createdAt
          updatedAt
          product {
            id
            name
            shortDescription
            description
            categories
            tags
            price
            salePrice
            saleFrom
            saleTo
            manageStock
            stock
            slug
            type
            state
            generalRules
            cancelationRules
            strikesRules
            trainingType
            generateSubscription
            subscriptionFrequency
            subscriptionSessions
            isTaxable
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
            resources {
              id
              name
              type
              description
              instructions
              onlyOn
              availableOn
            }
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      progress {
        items {
          id
          customerID
          dateCreated
          measurements {
            weight
            chest
            waist
            hips
            biceps
            thighs
          }
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      resources {
        items {
          id
          dateCreated
          resourceID
          productID
          customerID
          resource {
            bucket
            key
            region
            identityId
          }
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      checkIns {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      cart {
        id
        customerID
        products {
          productId
          qty
        }
        coupons
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          orderNumber
          orderDate
          customerID
          products {
            productId
            qty
            price
            tax
            total
            isTaxable
            name
            type
            trainingType
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
          }
          subtotal
          discounts
          shipping
          tax
          total
          coupons
          notes {
            customerID
            message
            dateCreated
          }
          paymentMethod
          paymentConfirmation
          status
          shippingCarrier
          trackingNumber
          fromSubscriptionID
          lastUpdate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createCustomerResource = /* GraphQL */ `
  mutation CreateCustomerResource(
    $input: CreateCustomerResourceInput!
    $condition: ModelCustomerResourceConditionInput
  ) {
    createCustomerResource(input: $input, condition: $condition) {
      id
      dateCreated
      resourceID
      productID
      customerID
      resource {
        bucket
        key
        region
        identityId
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCustomerResource = /* GraphQL */ `
  mutation UpdateCustomerResource(
    $input: UpdateCustomerResourceInput!
    $condition: ModelCustomerResourceConditionInput
  ) {
    updateCustomerResource(input: $input, condition: $condition) {
      id
      dateCreated
      resourceID
      productID
      customerID
      resource {
        bucket
        key
        region
        identityId
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCustomerResource = /* GraphQL */ `
  mutation DeleteCustomerResource(
    $input: DeleteCustomerResourceInput!
    $condition: ModelCustomerResourceConditionInput
  ) {
    deleteCustomerResource(input: $input, condition: $condition) {
      id
      dateCreated
      resourceID
      productID
      customerID
      resource {
        bucket
        key
        region
        identityId
      }
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      name
      userId
      dateCreated
      type
      createdAt
      updatedAt
      checkIn {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      name
      userId
      dateCreated
      type
      createdAt
      updatedAt
      checkIn {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      name
      userId
      dateCreated
      type
      createdAt
      updatedAt
      checkIn {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createCheckIn = /* GraphQL */ `
  mutation CreateCheckIn(
    $input: CreateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    createCheckIn(input: $input, condition: $condition) {
      id
      checkinDate
      customerID
      sessionID
      subscriptionID
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCheckIn = /* GraphQL */ `
  mutation UpdateCheckIn(
    $input: UpdateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    updateCheckIn(input: $input, condition: $condition) {
      id
      checkinDate
      customerID
      sessionID
      subscriptionID
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCheckIn = /* GraphQL */ `
  mutation DeleteCheckIn(
    $input: DeleteCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    deleteCheckIn(input: $input, condition: $condition) {
      id
      checkinDate
      customerID
      sessionID
      subscriptionID
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      customerID
      products {
        productId
        qty
      }
      coupons
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      customerID
      products {
        productId
        qty
      }
      coupons
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      customerID
      products {
        productId
        qty
      }
      coupons
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      code
      description
      amount
      type
      rules {
        expirationDate
        minItems
        canCombine
        minCartAmount
        maxUse
      }
      state
      timesUsed
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      code
      description
      amount
      type
      rules {
        expirationDate
        minItems
        canCombine
        minCartAmount
        maxUse
      }
      state
      timesUsed
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      code
      description
      amount
      type
      rules {
        expirationDate
        minItems
        canCombine
        minCartAmount
        maxUse
      }
      state
      timesUsed
      dateCreated
      lastUpdate
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      orderNumber
      orderDate
      customerID
      products {
        productId
        qty
        price
        tax
        total
        isTaxable
        name
        type
        trainingType
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
      }
      subtotal
      discounts
      shipping
      tax
      total
      coupons
      notes {
        customerID
        message
        dateCreated
      }
      paymentMethod
      paymentConfirmation
      status
      shippingCarrier
      trackingNumber
      fromSubscriptionID
      lastUpdate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      orderNumber
      orderDate
      customerID
      products {
        productId
        qty
        price
        tax
        total
        isTaxable
        name
        type
        trainingType
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
      }
      subtotal
      discounts
      shipping
      tax
      total
      coupons
      notes {
        customerID
        message
        dateCreated
      }
      paymentMethod
      paymentConfirmation
      status
      shippingCarrier
      trackingNumber
      fromSubscriptionID
      lastUpdate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateContactForm = /* GraphQL */ `
  mutation UpdateContactForm(
    $input: UpdateContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    updateContactForm(input: $input, condition: $condition) {
      id
      name
      message
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactForm = /* GraphQL */ `
  mutation DeleteContactForm(
    $input: DeleteContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    deleteContactForm(input: $input, condition: $condition) {
      id
      name
      message
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateErrorLog = /* GraphQL */ `
  mutation UpdateErrorLog(
    $input: UpdateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    updateErrorLog(input: $input, condition: $condition) {
      id
      method
      error
      stackTrace
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteErrorLog = /* GraphQL */ `
  mutation DeleteErrorLog(
    $input: DeleteErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    deleteErrorLog(input: $input, condition: $condition) {
      id
      method
      error
      stackTrace
      data
      createdAt
      updatedAt
    }
  }
`;
export const createTimestamp = /* GraphQL */ `
  mutation CreateTimestamp(
    $input: CreateTimestampInput!
    $condition: ModelTimestampConditionInput
  ) {
    createTimestamp(input: $input, condition: $condition) {
      id
      customerID
      date
      time
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTimestamp = /* GraphQL */ `
  mutation UpdateTimestamp(
    $input: UpdateTimestampInput!
    $condition: ModelTimestampConditionInput
  ) {
    updateTimestamp(input: $input, condition: $condition) {
      id
      customerID
      date
      time
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTimestamp = /* GraphQL */ `
  mutation DeleteTimestamp(
    $input: DeleteTimestampInput!
    $condition: ModelTimestampConditionInput
  ) {
    deleteTimestamp(input: $input, condition: $condition) {
      id
      customerID
      date
      time
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCustomerSubscription = /* GraphQL */ `
  mutation UpdateCustomerSubscription(
    $input: UpdateCustomerSubscriptionInput!
    $condition: ModelCustomerSubscriptionConditionInput
  ) {
    updateCustomerSubscription(input: $input, condition: $condition) {
      id
      dateCreated
      autoRenew
      nextPaymentDate
      productID
      customerID
      orderID
      lastRenewal
      remainingSessions
      createdAt
      updatedAt
      product {
        id
        name
        shortDescription
        description
        categories
        tags
        price
        salePrice
        saleFrom
        saleTo
        manageStock
        stock
        slug
        type
        state
        generalRules
        cancelationRules
        strikesRules
        trainingType
        generateSubscription
        subscriptionFrequency
        subscriptionSessions
        isTaxable
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
        resources {
          id
          name
          type
          src {
            bucket
            key
            region
            identityId
          }
          description
          instructions
          onlyOn
          availableOn
        }
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCustomerProgress = /* GraphQL */ `
  mutation UpdateCustomerProgress(
    $input: UpdateCustomerProgressInput!
    $condition: ModelCustomerProgressConditionInput
  ) {
    updateCustomerProgress(input: $input, condition: $condition) {
      id
      customerID
      dateCreated
      measurements {
        weight
        chest
        waist
        hips
        biceps
        thighs
      }
      images {
        id
        src {
          bucket
          key
          region
          identityId
        }
        primary
        smallThumbnail {
          bucket
          key
          region
          identityId
        }
        mediumThumbnail {
          bucket
          key
          region
          identityId
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      profilePic {
        bucket
        key
        region
        identityId
      }
      gender
      height
      weight
      weightGoal
      interests
      billingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      shippingAddress {
        line1
        line2
        city
        state
        zipCode
      }
      stripeInfo {
        customerId
        payment {
          id
          brand
          last4
          expMonth
          expYear
        }
      }
      strikes
      isAdmin
      isPetuPunchAdmin
      pin
      vacuID {
        bucket
        key
        region
        identityId
      }
      dateCreated
      lastUpdate
      searchString
      createdAt
      updatedAt
      subscriptions {
        items {
          id
          dateCreated
          autoRenew
          nextPaymentDate
          productID
          customerID
          orderID
          lastRenewal
          remainingSessions
          createdAt
          updatedAt
          product {
            id
            name
            shortDescription
            description
            categories
            tags
            price
            salePrice
            saleFrom
            saleTo
            manageStock
            stock
            slug
            type
            state
            generalRules
            cancelationRules
            strikesRules
            trainingType
            generateSubscription
            subscriptionFrequency
            subscriptionSessions
            isTaxable
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
            resources {
              id
              name
              type
              description
              instructions
              onlyOn
              availableOn
            }
            dateCreated
            lastUpdate
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      progress {
        items {
          id
          customerID
          dateCreated
          measurements {
            weight
            chest
            waist
            hips
            biceps
            thighs
          }
          images {
            id
            src {
              bucket
              key
              region
              identityId
            }
            primary
            smallThumbnail {
              bucket
              key
              region
              identityId
            }
            mediumThumbnail {
              bucket
              key
              region
              identityId
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      resources {
        items {
          id
          dateCreated
          resourceID
          productID
          customerID
          resource {
            bucket
            key
            region
            identityId
          }
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      checkIns {
        items {
          id
          checkinDate
          customerID
          sessionID
          subscriptionID
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
      cart {
        id
        customerID
        products {
          productId
          qty
        }
        coupons
        dateCreated
        lastUpdate
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          orderNumber
          orderDate
          customerID
          products {
            productId
            qty
            price
            tax
            total
            isTaxable
            name
            type
            trainingType
            dimensions {
              length
              width
              height
            }
            weight
            images {
              id
              primary
            }
          }
          subtotal
          discounts
          shipping
          tax
          total
          coupons
          notes {
            customerID
            message
            dateCreated
          }
          paymentMethod
          paymentConfirmation
          status
          shippingCarrier
          trackingNumber
          fromSubscriptionID
          lastUpdate
          createdAt
          updatedAt
          customer {
            id
            firstName
            lastName
            email
            phone
            profilePic {
              bucket
              key
              region
              identityId
            }
            gender
            height
            weight
            weightGoal
            interests
            billingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            shippingAddress {
              line1
              line2
              city
              state
              zipCode
            }
            stripeInfo {
              customerId
            }
            strikes
            isAdmin
            isPetuPunchAdmin
            pin
            vacuID {
              bucket
              key
              region
              identityId
            }
            dateCreated
            lastUpdate
            searchString
            createdAt
            updatedAt
            subscriptions {
              nextToken
            }
            progress {
              nextToken
            }
            resources {
              nextToken
            }
            checkIns {
              nextToken
            }
            cart {
              id
              customerID
              coupons
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
            orders {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      orderNumber
      orderDate
      customerID
      products {
        productId
        qty
        price
        tax
        total
        isTaxable
        name
        type
        trainingType
        dimensions {
          length
          width
          height
        }
        weight
        images {
          id
          src {
            bucket
            key
            region
            identityId
          }
          primary
          smallThumbnail {
            bucket
            key
            region
            identityId
          }
          mediumThumbnail {
            bucket
            key
            region
            identityId
          }
        }
      }
      subtotal
      discounts
      shipping
      tax
      total
      coupons
      notes {
        customerID
        message
        dateCreated
      }
      paymentMethod
      paymentConfirmation
      status
      shippingCarrier
      trackingNumber
      fromSubscriptionID
      lastUpdate
      createdAt
      updatedAt
      customer {
        id
        firstName
        lastName
        email
        phone
        profilePic {
          bucket
          key
          region
          identityId
        }
        gender
        height
        weight
        weightGoal
        interests
        billingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        shippingAddress {
          line1
          line2
          city
          state
          zipCode
        }
        stripeInfo {
          customerId
          payment {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        strikes
        isAdmin
        isPetuPunchAdmin
        pin
        vacuID {
          bucket
          key
          region
          identityId
        }
        dateCreated
        lastUpdate
        searchString
        createdAt
        updatedAt
        subscriptions {
          items {
            id
            dateCreated
            autoRenew
            nextPaymentDate
            productID
            customerID
            orderID
            lastRenewal
            remainingSessions
            createdAt
            updatedAt
            product {
              id
              name
              shortDescription
              description
              categories
              tags
              price
              salePrice
              saleFrom
              saleTo
              manageStock
              stock
              slug
              type
              state
              generalRules
              cancelationRules
              strikesRules
              trainingType
              generateSubscription
              subscriptionFrequency
              subscriptionSessions
              isTaxable
              weight
              dateCreated
              lastUpdate
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        progress {
          items {
            id
            customerID
            dateCreated
            measurements {
              weight
              chest
              waist
              hips
              biceps
              thighs
            }
            images {
              id
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        resources {
          items {
            id
            dateCreated
            resourceID
            productID
            customerID
            resource {
              bucket
              key
              region
              identityId
            }
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        checkIns {
          items {
            id
            checkinDate
            customerID
            sessionID
            subscriptionID
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        cart {
          id
          customerID
          products {
            productId
            qty
          }
          coupons
          dateCreated
          lastUpdate
          createdAt
          updatedAt
        }
        orders {
          items {
            id
            orderNumber
            orderDate
            customerID
            products {
              productId
              qty
              price
              tax
              total
              isTaxable
              name
              type
              trainingType
              weight
            }
            subtotal
            discounts
            shipping
            tax
            total
            coupons
            notes {
              customerID
              message
              dateCreated
            }
            paymentMethod
            paymentConfirmation
            status
            shippingCarrier
            trackingNumber
            fromSubscriptionID
            lastUpdate
            createdAt
            updatedAt
            customer {
              id
              firstName
              lastName
              email
              phone
              gender
              height
              weight
              weightGoal
              interests
              strikes
              isAdmin
              isPetuPunchAdmin
              pin
              dateCreated
              lastUpdate
              searchString
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createContactForm = /* GraphQL */ `
  mutation CreateContactForm(
    $input: CreateContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    createContactForm(input: $input, condition: $condition) {
      id
      name
      message
      email
      createdAt
      updatedAt
    }
  }
`;
export const createErrorLog = /* GraphQL */ `
  mutation CreateErrorLog(
    $input: CreateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    createErrorLog(input: $input, condition: $condition) {
      id
      method
      error
      stackTrace
      data
      createdAt
      updatedAt
    }
  }
`;
